import React, { Component } from 'react'
import './Dashboard.css'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from 'react-modal';
import Topbar from '../topbar/Topbar';
import ReactJson from 'react-json-view'
import DataObjectIcon from '@mui/icons-material/DataObject';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Error from '../login-notif/Error';
import { Typography } from '@mui/material';

let eve = null;
const cpypostArray =[]
const customStyles = {
    content: {
      height: '80%',
      width: '70%',  
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '999',
      overflow: 'scroll'
    },
  };
class Dashboard extends Component {
    
    
    constructor(props) {
        super(props);
        if (localStorage.getItem('email') == "" || localStorage.getItem('email') == null || localStorage.getItem('email') == undefined) {
            return(
                <Error/>
            )
        }
        this.closeModal = this.closeModal.bind(this)
        this.showDetails = this.showDetails.bind(this)
        this.state = {
            filteredRows: [],
            totalRows: 0,
            page: 0,
            rowsPerPage: 10,
            games: new Set(),
            gameList:[],
            game: "",
            event: "",
            modalIsOpen: false,
            eventName: "",
            data: null,
            list_items: [],
            revenue: 0,
            names: new Set(),
            listEvents: []
      }
    }

    changeGameName = (event) => {
        this.setState({
            game: event.target.value
        })
      };

    componentWillMount() {
        if (cpypostArray.length > 0) {
            this.updateStates();
        }

        if (localStorage.getItem('email') == "" || localStorage.getItem('email') == null || localStorage.getItem('email') == undefined) {
            return(
               <Error/>
            )
        }
        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
            body: JSON.stringify({
                username: "gc_realtime_logs",
                password: "gc_platform_logs@123"
            })
        };
        fetch("https://platform-api.gamecircus.com/auth", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            var token = result["access_token"]
            eve = new EventSource("https://platform-api.gamecircus.com/logs", { authorizationHeader: "JWT " + token })
            eve.onmessage = (event) => {
                this.updateList(event)
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error)
          }
        )
    }

    updateList(event){
        this.state.names.add(event['eventName'])
        let data = JSON.parse(event.data)
        cpypostArray.push(data)
       this.updateStates()
    }

    updateStates() {
        this.setState({
            list_items :[],
            gameList: [],
            listEvents:[]
            })
           this.setState({
             gameList: cpypostArray.slice().map((json, index) => json["gameName"]),
             listEvents: cpypostArray.slice().map((json, index) => json),  
             list_items : cpypostArray.slice().map((json, index) => json["eventName"]),
            
        },() => {
            this.setState({
                names: new Set(this.state.list_items),
                games: new Set(this.state.gameList)
                })
        })
    }

    shouldComponentUpdate(nextProps, nextState){
        console.log("updated")
        
        if (nextState.listEvents > 0) {
            const filtered = this.getAllTableRows(nextState.listEvents)
            nextState.filteredRows = filtered
            nextState.totalRows = filtered.length
        }
        
       console.log(nextState)
       return true
    }

    componentWillUnmount() {
       if (eve != null) {
        eve.close();
       }
    }

    getJsonData() {
        return 
    }

    changeEventName = (event) => {
        this.setState({
            eventName: event.target.value
        })
      };

    showDetails(event) {
        if (this.state.modelIsOpen) {
            this.setState({
                modalIsOpen: false,
                event: event
            })
        } else {
            this.setState({
                modalIsOpen: true,
                event: event
            })
        }
    }

    closeModal() {
        this.setState({
            modalIsOpen: false
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })
      };
    
      handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage:+event.target.value, 
            page: 0
        })
      };
    
    getAllTableRows(events) {
        var list = events.filter(event => event["gameName"] == this.state.game).filter(event => (this.state.eventName == ""? true: this.state.eventName == event["eventName"]))
        // this.setState({
        //     filteredRows: list.length
        // })
        console.log("called")
        return list;
    }

    getLoadingComponent() {
        return (
            <div className='loading-main'>
                 <img className='bt' src ={process.env.PUBLIC_URL + "LoadingGIF.gif"}></img>
                 <br/><br/>
                 <Typography>
                     Wait while we connect with the remote stream, this might take some time.
                 </Typography>
            </div>
        )
    }

    render() {
        const  nameList = Array.from(this.state.names).map((name) =>
        <MenuItem value={name}>{name}</MenuItem>
        );
        const  gameNameList = Array.from(this.state.games).map((name) =>
        <MenuItem value={name}>{name}</MenuItem>
        );
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
              backgroundColor: theme.palette.common.black,
              color: theme.palette.common.white,
            },
            [`&.${tableCellClasses.body}`]: {
              fontSize: 14,
            },
          }));
          
        return (
    
            <>
                    <Topbar/>           
                    { cpypostArray.length > 0?
                        <div className='mainpage'>
                        <Typography variant='h3'>
                            Event SDK Metrices
                        </Typography>
                        <div className='dash'>
                            <div className='names'>
                                <FormControl fullWidth variant="filled">
                                <InputLabel id="demo-simple-select-label">Game</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.game}
                                    label="Select Game Name"
                                    onChange={this.changeGameName}
                                >
                                   {this.state.listEvents.length > 0? gameNameList:<ul>Loading....</ul>}
                                </Select>
                                </FormControl>
                                <FormControl fullWidth variant="filled">
                                <InputLabel id="demo-simple-select-label">Event</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.eventName}
                                    label="Select Game Name"
                                    onChange={this.changeEventName}
                                >
                                   {this.state.listEvents.length > 0? nameList:<ul>Loading....</ul>}
                                </Select>
                                </FormControl>
                            </div>
                            <div className='info'>
                                <div className='meta'>
                                    {
                                        this.state.eventName &&
                                        <Stack direction="row" spacing={1}>
                                        <Chip variant="outlined"  label={this.state.game} />
                                        <Chip variant="outlined"  label={this.state.eventName}/>
                                        </Stack>

                                    }
                                </div>
                                <div className='table'>
                                <Modal
                                    isOpen={this.state.modalIsOpen}
                                    onAfterOpen={this.afterOpenModal}
                                    onRequestClose={this.closeModal}
                                    contentLabel="Event Details"
                                    style={customStyles}
                                    >
                                        <div className="modelBody" >
                                            <ReactJson src={this.state.event} />
                                        </div>
                                        
                                </Modal>
                                {this.state.eventName === ""?
                                    <>
                                    <Typography>
                                            Select game and event name to get started...
                                        </Typography>
                                </>
                                :
                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader aria-label="sticky table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Game Name</StyledTableCell>
                                            <StyledTableCell>User ID</StyledTableCell>
                                            <StyledTableCell>Session ID</StyledTableCell>
                                            <StyledTableCell>Session No.</StyledTableCell>
                                            <StyledTableCell>Timestamp</StyledTableCell>
                                            <StyledTableCell align="right">Option</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody className='tbody'>
                                        {this.getAllTableRows(this.state.listEvents)
                                        .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) 
                                        .map((row) => (
                                            <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row["gameName"].toUpperCase()}
                                            </TableCell>
                                            <TableCell>{row["userID"]}</TableCell>
                                            <TableCell>{row["sessionID"]}</TableCell>
                                            <TableCell>{row["sessionNumber"]}</TableCell>
                                            <TableCell>{row["timestamp"]}</TableCell>
                                            <TableCell align="right" onClick={e => this.showDetails(row)} className="detail"><DataObjectIcon/></TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                        
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={this.getAllTableRows(this.state.listEvents).length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                                </Paper>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>            
                    :
                    this.getLoadingComponent()
                    }
            </>
        )
    }
}

export default Dashboard


