import { Typography } from '@mui/material';
import React from 'react';
import './table-row.css'
import PersonIcon from '@mui/icons-material/Person';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import VerifiedIcon from '@mui/icons-material/Verified';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

export default function TableRow(props) {
    function getVerification(status) {
        if (status === "Verified") {
            var stylee = {
                fill: "green"
            }
            return <div>
                 <VerifiedIcon style={stylee}/>{"  "}Verified
            </div>
        } else {
            var stylee = {
                fill: "#dc3545"
            }
            return <div>
                 <GppMaybeIcon  style={stylee}/>{"  "}Un-Verified
            </div>
        }
    }
    return (
        <>         
               <div className='table-row'>
                        <div className='table-row-headline'>
                            <div className='table-row-name'>
                                <Typography>{props.name}</Typography>
                            </div>
                            <div className='table-row-tray'>
                                <Typography><PersonIcon/>{"  "}{props.createdBy}</Typography>
                                <Typography><WatchLaterIcon/> {"  "}{props.createdDate}</Typography>
                                <Typography>{getVerification(props.verificationStatus)}</Typography>
                                <Typography><ViewColumnIcon/>{"  "}{props.totalColumns} Columns</Typography>
                            </div>
                        </div>
                        <div className='table-row-description'>
                            <Typography><q>{props.description}</q></Typography>
                        </div>
                    </div>            
        </>
    )
}