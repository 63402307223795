import React, { Component } from "react";
import { DeleteOutline } from "@material-ui/icons";
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./chart.css";
export class Post extends Component {
  render() {
    return (
      <div>
        <div className="chart">
          <h3 className="chartTitle">
            {this.props.title}
            <span className="chartDelete">
              <DeleteOutline onClick={this.props.delete} />
            </span>
            <span className="chartAdd">
              <DeleteOutline onClick={this.props.add} />
            </span>
          </h3>

          <ResponsiveContainer width="100%" aspect={4 / 1}>
            <LineChart data={this.props.data}>
              <XAxis dataKey="name" stroke="#5550bd" />
              <Line
                type="monotone"
                dataKey={this.props.dataKey}
                stroke="#5550bd"
              />
              <Tooltip />
              {this.props.grid && (
                <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default Post;
