import * as React from 'react';
import './main.css';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import Divider from '@mui/material/Divider';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import DnsSharpIcon from '@mui/icons-material/DnsSharp';
import Modal from 'react-modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Error from '../login-notif/Error';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dashboard from '../dashboard/Dashboard';
import { Link } from "react-router-dom";
import Topbar from '../topbar/Topbar';



export default function MainComponent() {
    const [open, setOpen] = React.useState(false);

    if (localStorage.getItem('email') == "" || localStorage.getItem('email') == null || localStorage.getItem('email') == undefined) {
        return(
            <Error/>
        )
    }

    function openBigtop() {
        var email = localStorage.getItem('email');
        
        try {
            fetch("https://bigtop-beta.gamecircus.com/login/?email=" + email, {
                redirect: 'manual',
                mode: 'cors'
            })
            .then(result => result)
            .then(
              (result) => {
                if (result.status == 0) {
                    window.open("https://bigtop-beta.gamecircus.com/login/?email=" + email, '_blank').focus();
                } else {
                    setOpen(true)
                }
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                console.log(error)
              }
            )
          } catch (err) {
            setOpen(true)
          }
        
    }


    const hrStyle = { height: '2rem'};

    const handleClose = (event) => {   
        setOpen(false);
      };
    
    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

     
    return (
        <div>
            <Snackbar open={open}  onClose={handleClose} autoHideDuration={3000} action={action}>
                <Alert  severity="error" sx={{ width: '100%' }}>
                    You don't have enough permissions
                </Alert>
            </Snackbar>
        <Topbar/>
        <div className='main-body'>
            <div className='items dashboards'>
                <div className='head'>
                    <Typography variant="h2" className='heading'>
                        <InsertChartOutlinedOutlinedIcon className='heading-icon'/>
                        <Badge badgeContent={2} color="primary">
                            Dashboards
                        </Badge>
                    </Typography>
                </div>
                <div className='body'>
                <Card sx={{ maxWidth: 345 }} onClick={openBigtop}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="200"
                        image={process.env.PUBLIC_URL + "big_top_wari2.png"}
                        alt="green iguana"
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Big-Top
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Bigtop contains all the dashboards containing Metrices KPIs and real time information about game sessions.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="200"
                        image={process.env.PUBLIC_URL + "dev.png"}
                        alt="green iguana"
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            RCV (under-dev)
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            RCV is under development right now, will be used to organise and control configurations.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                </div>
                <div className='footer'>
                    <Typography>
                        Don't have Access? Please connect Admin.
                    </Typography>
                </div>
            </div>
            <Divider style={hrStyle}/>
            <div className='items dashboards'>
                <div className='head'>
                    <Typography variant="h2" className='heading'>
                        <DataObjectOutlinedIcon className='heading-icon'/>
                        <Badge badgeContent={1} color="primary">
                            Log Analysis
                        </Badge>
                    </Typography>
                </div>
                <div className='body'>
                <Link to={"/logs"} className='link'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={process.env.PUBLIC_URL + "tech.png"}
                            alt="green iguana"
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                GC SDK Metrices
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Select app name and event name and see logs in real-time.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Link>
                </div>
                <div className='footer'>
                    <Typography>
                        Don't have Access? Please connect Admin.
                    </Typography>
                </div>
            </div>
            <Divider style={hrStyle}/>
            <div className='items dashboards'>
                <div className='head'>
                    <Typography variant="h2" className='heading'>
                        <DnsSharpIcon className='heading-icon'/>
                        <Badge badgeContent={1} color="primary">
                            Metadata
                        </Badge>
                    </Typography>
                </div>
                <div className='body'>
                <Link to={"/metadata"} className='link'>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardActionArea>
                            <CardMedia
                            component="img"
                            height="200"
                            image={process.env.PUBLIC_URL + "redshift.png"}
                            alt="green iguana"
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                GC SDK Warehouse
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Get all information of your Redshift tables and their fields.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Link>
                </div>
                <div className='footer'>
                    <Typography>
                        Don't have Access? Please connect Admin.
                    </Typography>
                </div>
            </div>
            
        </div>
        <div className='main-footer'>
                <img src={process.env.PUBLIC_URL + "GCLogo_Blue.png"} className='gc-logo'></img>
                <Typography>
                    Platform by Gamecircus
                </Typography>
                <Typography>
                    For any issue, contact ITSupport@gamecircus.com
                </Typography>
                <Typography>
                    Copyright © 2010 - 2020 Game Circus
                </Typography>
        </div>
        </div>
    )
}