import React from "react";
import RealtimeLineChart from "./RealtimeLineChart";

const TIME_RANGE_IN_MILLISECONDS = 30 * 1000;
const ADDING_DATA_INTERVAL_IN_MILLISECONDS = 1000;
const ADDING_DATA_RATIO = 0.8;
let sum = 0;
export default () => {
  const nameList = ["a"];
  const defaultDataList = nameList.map((name) => ({
    name: name,
    data: [],
  }));
  const [dataList, setDataList] = React.useState(defaultDataList);

  React.useEffect(() => {
    const eve = new EventSource("http://localhost:5000/logs");
    eve.onmessage = (event) => {
      let revenue = JSON.parse(event.data);
      let revenueNumber = Number(revenue["event_revenue"]);
      sum = sum + revenueNumber;
      setDataList(
        dataList.map((val) => {
          return {
            name: val.name,
            data: [
              ...val.data,
              {
                x: new Date(),
                y: sum,
              },
            ],
          };
        })
      );
    };

    return () => {
      eve.close();
    };
  });

  return (
    <div>
      <RealtimeLineChart
        dataList={dataList}
        range={TIME_RANGE_IN_MILLISECONDS}
      />
    </div>
  );
};
