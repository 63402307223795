import React, { Component } from 'react';
import Chart from 'react-apexcharts'

let eve = null;
let newSeries = [];

class Donut extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        labels: ['IOS', 'Android']
      },
      series: [0, 0]
    }
  }

  componentDidMount() {
    eve = new EventSource("http://localhost:5000/logs")
    eve.onmessage = (event) => { 
        this.updateGraph(event)
    }
}

componentWillUnmount() {
    if (eve != null) {
     eve.close();
    }
 }

updateGraph(event) {
    let data = JSON.parse(event.data)
    if (data["platform"] !== null) {
        let platform = data["platform"]
        this.updateState(platform)   
    }
}

updateState(value) {
    console.log(value)
    newSeries = this.state.series.slice(0);
    if (value === 'android') {
      newSeries[1] = newSeries[1] + 1;
    } else {
      newSeries[0] = newSeries[0] + 1;
    }

    this.setState({
      series: newSeries,
      labels: ['IOS', 'Android']
    })
}

  render() {

    return (
      <div className="donut">
        <Chart options={this.state.options} series={this.state.series} type="donut" width="380" />
      </div>
    );
  }
}

export default Donut;