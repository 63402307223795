import "./sidebar.css";
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DnsIcon from '@mui/icons-material/Dns';
import { Link } from "react-router-dom";
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default function Sidebar() {

  return (
        <div className="sidebarMenu">
           <Typography variant="h4" className='heading'>
              Explore..
            </Typography>
          <ul className="sidebarList">
          <Link to="/main" className="link">
            <li className="sidebarListItem "  >
               <InsertChartOutlinedOutlinedIcon/>
                Dashboards
            </li>
            </Link>
            <Divider/>
            <Link to="/logs" className="link">
            <li className="sidebarListItem ">
              <DataObjectIcon/>
              Live Metrices
            </li>
            </Link>
            <Divider/>
            <Link to="/metadata" className="link">
            <li className="sidebarListItem ">
              <DnsIcon/>
              Metadata
            </li>
            </Link>
          </ul>
        </div>
    )
}
