import React, { Component } from 'react'
import './EventStats.css'
import Realtime from '../graphs/realtime/Realtime';
import BarSimple from '../graphs/bar/BarSimple';
import Donut from '../graphs/donut/Donut';

class EventStats extends Component {


    render() {
        return (
           <div className="events">
               <div className="graph">
                    <div className="can">
                        <Realtime/>
                    </div>
                    <div className="can">
                        <BarSimple/>
                    </div>
               </div>
               <div className="graph">
                    <div className="can">
                        <Donut/>
                    </div>
               </div>
            </div>
        )
    }
}

export default EventStats
