import EventStats from "./Component/eventStats/EventStats"
import Home from "./Pages/home/Home";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Container/Login";
import "bootstrap/dist/css/bootstrap.css";
import DataCircus from "./Component/datacircus/DataCircus";
import Metadata from "./Component/metadata/metadata";
import MainComponent from "./Component/main/main";


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/login" element={<Login/>}></Route>
          <Route path="/dashboards" element={<Login/>}></Route>
          <Route path="/logs" element={<Home/>}></Route>
          <Route path="/metadata" element={<Metadata/>}></Route>
          <Route path ="/stats" element ={EventStats}></Route>
          <Route path="/main" element={<MainComponent/>}></Route>
          <Route path="/health">
            <>Hey There!!! The App is Healthy</>  
          </Route>
          <Route path="/" element={<Login/>}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
