import { Typography } from '@mui/material';
import React, {useEffect} from 'react';
import './column-view.css';
import Checkbox from '@mui/material/Checkbox';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import VerifiedIcon from '@mui/icons-material/Verified';
import Modal from 'react-modal';
import EditIcon from '@mui/icons-material/Edit';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const customStyles = {
    content: {
      height: '70%',
      width: '60%',  
      top: '30%',
      left: '30%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-5%',
      transform: 'translate(-20%, -20%)',
      border: '2px solid #00b3e4',
      overflow: 'none'
    },
  };


export default function ColumnView({ table, refreshFunction, parameter }) {
    const [tableEdit, setTableEdit] = React.useState(false);
    const [selectedColumnDescription, setSelectedColumnDescription] = React.useState(false);
    const [selectedColumnID, setSelectedColumnID] = React.useState(false);
    const [columnEdit, setColumnEdit] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [tableEditDescriptionValue, setTableEditDescriptionValue] = React.useState({});
    const [allTags, setAllTags] = React.useState([]);
    const [allTagsWithBoxes, setAllTagsWithBoxes] = React.useState([]);
    const [currentTableTags, setCurrentTableTags]= React.useState([]);


    function handleTagChanged(value) {
        if (value.checked) {
            addTag(table["table_id"], value.value, "table")
        } else {
            removeTag(table["table_id"], value.value, "table")
        }
    }


    useEffect(() => {
        getAllTags()
        var currentTableTagList = table["tags"].map(value => {
            return value["tag_id"]
        })
        setCurrentTableTags(currentTableTagList)
        setTableEditDescriptionValue(table["description"] == "Not Available" ? "": table["description"])
        const data = table["verification_status"]
        const status1 = data[0]
        const status = status1["status"] || "Un-Verified"

        if (status == "Un-Verified") {
            setVerified(false)
        } else {
            setVerified(true)
        }

      },[]);

    useEffect(() => {

        var li = allTags.map((data, index) => {
            return (
                <li id={index}>
                    <FormControlLabel control={ 
                        currentTableTags.includes(data["tag_id"])?
                        <Checkbox
                            defaultChecked 
                            value={data["tag_id"]}
                            onChange={(event) => handleTagChanged(event.target)}
                        />:
                        <Checkbox
                            value={data["tag_id"]}
                            onChange={(event) => handleTagChanged(event.target)}
                        />

                    } 
                        label={data["tag_name"] + ": " + data["tag_description"]} />
                </li>
            )
        })
        setAllTagsWithBoxes(li)
    }, [allTags])

    useEffect(() => {
        getAllTags()
        var currentTableTagList = table["tags"].map(value => {
            return value["tag_id"]
        })
        setCurrentTableTags(currentTableTagList)
    }, [table])


    function openTableEdit() {

        setTableEdit(true);
    }

    function openColumnEdit(column) {
        setSelectedColumnDescription(column["description"])
        setSelectedColumnID(column["column_id"])
        setColumnEdit(true);
    }

    function closeEdit() {
        setTableEdit(false);
        setColumnEdit(false)
    }

    function verify(verification) {
       if (verification) {
        const status1 = verification[0]
        var status = status1["status"]
        if (status === "Verified") {
            var stylee = {
                fill: "green"
            }
            return <div>
                 <VerifiedIcon style={stylee}/>{"  "}Verified
            </div>
        } else {
            var stylee = {
                fill: "#dc3545"
            }
            return <div>
                 <GppMaybeIcon  style={stylee}/>{"  "}Un-Verified
            </div>
        }
       }
    }

    function getVerificationId() {
        const data = table["verification_status"]

        const status = data[0]

        const id =status["verification_id"]

        return id
    }

    function createColumns(columns) {
        var list = columns.map(column => 
            <div className='table-row' onClick={() => openColumnEdit(column)}>
            <div className='table-row-headline'>
                <div className='table-row-name'>
                    <Typography>{column["name"]}</Typography>
                </div>
                <div className='table-row-tray'>
                    <Typography><WatchLaterIcon/> {"  "}{column["date"]}</Typography>
                </div>
            </div>
            <div className='table-row-description'>
                <Typography><q>{column["description"]}</q></Typography>
            </div>
        </div>  
            )
        return list
    }

    function addTag(id, tag_id, type) {
        const body_content = {
            "type": type,
            "id": id,
            "tag_id": tag_id
        }
        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
            body:  JSON.stringify(body_content)
        };
            fetch("https://platform-metadata.gamecircus.com/tag_relations/insert", requestOptions)
            .then(res => res)
            .then(
                (result) => {
                    refreshFunction(parameter)
                },
                (error) => {
                    alert(error)
                }
            )
     
    }

    function removeTag(id, tag_id, type) {
        const body_content = {
            "type": type,
            "id": id,
            "tag_id": tag_id
        }
        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
            body:  JSON.stringify(body_content)
        };
            fetch("https://platform-metadata.gamecircus.com/tag_relations/delete", requestOptions)
            .then(res => res)
            .then(
                (result) => {
                    refreshFunction(parameter)
                },
                (error) => {
                    alert(error)
                }
            )
     
    }

    function saveTableEdit() {
        closeEdit()
        const body_content = {
            "table_id": table["table_id"],
            "table_description": tableEditDescriptionValue,
            "created_user": "Not Available",
            "last_updated_by": localStorage.getItem("email")
        }
        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
            body:  JSON.stringify(body_content)
        };
            fetch("https://platform-metadata.gamecircus.com/table/update", requestOptions)
            .then(res => res)
            .then(
                (result) => {
                    refreshFunction(parameter)
                },
                (error) => {
                    alert(error)
                }
            )
     
    }

    function getAllTags() {
        const requestOptions = {
            mode: 'cors',
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
        };
            fetch("https://platform-metadata.gamecircus.com/tags", requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setAllTags(result)
                },
                (error) => {
                    alert(error)
                }
            )
     
    }


    function saveColumnEdit() {
        closeEdit()
        const body_content = {
            "column_id": selectedColumnID,
            "column_description": selectedColumnDescription,
            "last_updated_by": localStorage.getItem("email")
        }
        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
            body:  JSON.stringify(body_content)
        };
            fetch("https://platform-metadata.gamecircus.com/column/update", requestOptions)
            .then(res => res)
            .then(
                (result) => {
                    refreshFunction(parameter)
                },
                (error) => {
                    alert(error)
                }
            )
     
    }

    function handleRadioChange(event){
        if (event.target.value === "Verified") {
            setVerified(true)
        } else if (event.target.value === "Un-Verified") {
            setVerified(false)
        } else {
            return
        }
        const body_content = {
            "verification_id":getVerificationId(),
            "status": event.target.value,
            "verified_by": localStorage.getItem("email")
        }
        const requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
            body:  JSON.stringify(body_content)
        };
            fetch("https://platform-metadata.gamecircus.com/verification/update", requestOptions)
            .then(res => res)
            .then(
                (result) => {
                    refreshFunction(parameter)
                },
                (error) => {
                   alert(error)
                }
            )
    }
    return (
        
        <>        
                <Modal
                    isOpen={tableEdit}
                    onRequestClose={closeEdit}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                  
                   <div className='model-content'>
                       <div className='description-input'>
                       <TextField fullWidth label="Add/Change description" id="fullWidth" multiline maxRows={2} minRows={2}
                            defaultValue={tableEditDescriptionValue} onChange={(event) => setTableEditDescriptionValue(event.target.value)}
                        />
                        <br/>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" onClick={saveTableEdit} startIcon={<SaveIcon/>}>Save</Button>
                            <Button variant="outlined" onClick={closeEdit} >Cancel</Button>
                        </Stack>
                       </div>
                        <div>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup
                            aria-label="Verification Status"
                            name="controlled-radio-buttons-group"
                            value={verified?"Verified":"Un-Verified"}
                            onChange={(event) => handleRadioChange(event)}
                        >
                            <FormControlLabel value="Verified" control={<Radio />} label="Verified" />
                            <FormControlLabel value="Un-Verified" control={<Radio />} label="Un-Verified" />
                        </RadioGroup>
                        </FormControl>
                        </div>
                        <div className='tableTags'>
                            <Typography>
                                Tags:
                               <ul>
                                   {allTagsWithBoxes}
                               </ul>
                            </Typography>
                        </div>
                   </div>
                </Modal> 
                <Modal
                    isOpen={columnEdit}
                    onRequestClose={closeEdit}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                  
                   <div className='model-content'>
                       <div className='description-input'>
                       <TextField fullWidth label="Add/Change description" id="fullWidth" multiline minRows={4}
                            defaultValue={selectedColumnDescription} onChange={(event) => setSelectedColumnDescription(event.target.value)}
                        />
                        <br/>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" onClick={saveColumnEdit} startIcon={<SaveIcon/>}>Save</Button>
                            <Button variant="outlined" onClick={closeEdit} >Cancel</Button>
                        </Stack>
                       </div>
                        <div>
                            <Typography>
                                Tags: (Comming soon)
                            </Typography>
                        </div>
                   </div>
                </Modal>
               <div className='column-view'>
                      <div className='table-details'>
                     <Typography onClick={() =>openTableEdit()} className='editButton'>
                     <EditIcon/>{"  "} Edit Table Details
                     </Typography>
                      <Typography>
                          {verify(table["verification_status"])}
                      </Typography>
                        <Typography>
                                Table: {table["name"]}
                        </Typography>
                        <Typography>
                                Date Created: {table["date"]}
                        </Typography>
                        <Typography>
                                Description: {table["description"]}
                        </Typography>
                        <Typography>
                                Last Updated: {table["last_updated"]}
                        </Typography>
                        <Typography>
                                Redshift ID: {table["redshift_table_id"]}
                        </Typography>
                        <Typography>
                                Schema: {table["schema"]}
                        </Typography>
                      </div>
                      <div className='columns'>
                            <Typography variant="h4" component="h4">
                                Columns
                            </Typography>
                             {createColumns( table["columns"])}
                      </div>
                </div>            
        </>
    )
}