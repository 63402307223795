import React, { useState } from "react";
import "./login.css";
import Oauth from "../Oauth/Oauth";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
  }
  
  var style = {

    backgroundImage: `url(${process.env.PUBLIC_URL + "bg.png"})`,
    backgroundSize: 'cover'
  }
  return (
    <div className="main">
        <div className="login">
          <div className="auth">
            <img src ={process.env.PUBLIC_URL + "GCLogo_Blue.png"}></img>
            <Oauth/>
          </div>
        </div>
        <div className="pics" style={ style }>
          <div className="container">
            <div>
              <img src ={process.env.PUBLIC_URL + "big_top_wari2.png"}></img>
            <br />
            <div className="by">
            <small>by<span>Gamecircus</span></small>
            </div>
            </div>
          </div>
        </div>
    </div>
  );
}
