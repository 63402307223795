import React, { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Error from '../login-notif/Error'
import Sidebar from '../sidebar/Sidebar'
import Topbar from '../topbar/Topbar'
import "./metadata.css"
import Typography from '@mui/material/Typography'
import StorageIcon from '@mui/icons-material/Storage';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import TableRow from './table-row/table-row';
import Modal from 'react-modal';
import ColumnView from './column-view/column-view';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';


const customStyles = {
    content: {
      height: '70%',
      width: '60%',  
      top: '30%',
      left: '30%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-5%',
      transform: 'translate(-20%, -20%)',
      border: '2px solid #00b3e4'
    },
  };


    function getAllTables(setData) {
        const requestOptions = {
            mode: 'cors',
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Headers': "*"
            },
        };
            fetch("https://platform-metadata.gamecircus.com/table/all", requestOptions)
            .then(res => res.json())
            .then(
            (result) => {
               setData(result)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                alert(error)
            }
            )
        }

        function getVerificationStatus(verification) {
            var details = verification[0]
            if (details) {
                return details["status"]
            }
            return "Un-Verified"
        }


        function get_tag_list(data) {
            const mySet = new Set() 
            for (var i = 0; i < data.length; i++) {
                var tags = data[i]["tags"]
                for (var j = 0; j < tags.length; j++) {
                    mySet.add(tags[j]["tag_name"])
                }
            }
            return mySet
        }

export default function Metadata() {
    const [data, setData] = useState([]);
    const [filteredData, setfilteredData] = useState([]);
    const [tables, setTables] = useState([]);
    const [verificationFilters, setVerificationFilters] = useState(new Map());
    const [tagFilters, setTagFilters] = useState(new Map());
    const [tags, setTags] = useState(new Set());
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedTable, setselectedTable] = React.useState({});
    const [valueChanged, somethingChanged] = React.useState(false)

    function checkForVerification(value) {
        if (!verificationFilters.get("Verified") && !verificationFilters.get("Un-Verified")) {
            return true
        }
        var status = getVerificationStatus(value["verification_status"])
        var result = verificationFilters.get(status) || false
        return result
    }

    function checkForTag(data) {
        if (tagFilters.size == 0) {
            return true
        }
        var taglist = data["tags"]
        for (let tag in taglist) {
            var single_tag = taglist[tag]      
            var res = tagFilters.get(single_tag["tag_name"])
            if (res) {
                return true
            }
        }
        return false                 
    }

    function checkForFilters(value) {
        if (checkForTag(value) && checkForVerification(value)) {
            return true
        }
        return false
    }

    function openModal(table) {
        setselectedTable(table)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function updateSelectedTable(currentTable) {
        const currentTableId = currentTable["table_id"]
        data.map(table => {
            if (table["table_id"] === currentTableId) {
                setselectedTable(table)
            }
        })
    }

    useEffect(() => {
        getAllTables(setData)
      }, []);

      useEffect(() => {
        getAllTables(setData)
      }, [valueChanged]);

      useEffect(() => {
        setTags(get_tag_list(data))
        setfilteredData(data)
        updateSelectedTable(selectedTable)
      }, [data]);

      useEffect(() => {
        var result = data.filter(value =>checkForFilters(value))
        setfilteredData(result)
      }, [verificationFilters, data, tagFilters]);

      useEffect(() => {
        var table_data = filteredData.reverse().map((value) => 
            <li index={value["table_id"]} onClick={() => openModal(value)}>
                    <TableRow name={value["name"]} 
                    createdBy={value["created_by"]} 
                    verificationStatus={getVerificationStatus(value["verification_status"])} 
                    createdDate={value["date"]} 
                    totalColumns={value["columns"].length} 
                    description={value["description"]}/> 
            </li>)
        
        setTables(table_data.slice(0))
      }, [filteredData, data]); 


    if (localStorage.getItem('email') == "" || localStorage.getItem('email') == null || localStorage.getItem('email') == undefined) {
        return (
          <Error/>
        )
      }

    
      function changedTag(status, tag_name){
        if (status) {
            tagFilters.set(tag_name, status)
        } else {
            tagFilters.delete(tag_name)
        }
        var merged = new Map(tagFilters)
        setTagFilters(merged)
    }
      
    function changed(status, checkbox_name){
        verificationFilters.set(checkbox_name, status)
        var merged = new Map(verificationFilters)
        setVerificationFilters(merged)
    }

     
    return(
        <>
            <Topbar/>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <ColumnView table={selectedTable} refreshFunction={getAllTables} parameter={setData}/>
            </Modal>
            <div className='kpi'>
                <div className='data'>
                <div className='options'>
                    <Typography variant="h4" component="h4" className='custom-heading'>
                        <StorageIcon fontSize="large"/>
                        Metadata
                    </Typography>
                    <Button variant="contained" onClick={() =>  getAllTables(setData)} startIcon={<RefreshIcon/>}>Refresh</Button>
                </div>
                <div className='catalog'>
                    <div className='filter filter-left'>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Schema</FormLabel>
                            <RadioGroup
                                aria-label="schema"
                                defaultValue="public"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="public" control={<Radio />} label="Public" />
                            </RadioGroup>                    
                        </FormControl>
                        <hr className='hr-c'></hr>
                        <FormGroup>
                        <FormLabel component="legend">Show tables</FormLabel>
                            <FormControlLabel           
                            control={<Checkbox 
                                onChange={function(event){
                                    changed(event.target.checked, "Verified")
                                }}
                            />} 
                            label="Verified" 
                            />
                            <FormControlLabel control={<Checkbox
                                onChange={function(event){
                                    changed(event.target.checked, "Un-Verified")
                                }}
                            />} label="Un-Verified" />
                        </FormGroup>
                    </div>
                    
                        {
                            data.length === 0 ?
                            <div className='gif'>
                                <img className='bt' src ={process.env.PUBLIC_URL + "LoadingGIF.gif"}></img>
                            </div>
                            :<div className='results'>
                            <ul> {tables}</ul>  
                            </div>
                        }                         
                    
                    <div className='filter tag-list filter-right'>
                        <FormGroup>
                        <FormLabel component="legend">Tags</FormLabel>
                            <ul>
                                {
                                    Array.from(tags).map((value, index) => 
                                        <li index={index}>
                                            <FormControlLabel control={<Checkbox
                                                onChange={function(event){
                                                    changedTag(event.target.checked, value)
                                                }}
                                            />} label={value} />
                                        </li>    
                                    )
                                }
                            </ul>
                        </FormGroup>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}