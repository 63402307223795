import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import jwt_decode from "jwt-decode";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

class Oauth extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loggedIn :null,
        username : null,
        email: null,
        imageUrl: null,
        open: false
  }
}
   responseGoogle(response){
    if (response){
      this.setState({
        loggedIn : true,
        username : response.name,
        imageUrl: response.picture,
        email: response.email
      })
      
    } else{
      this.setState({
        loggedIn : false,
        username:null,
        open:true
      }) 
    } 
  };

  handleClose(event){   
    this.setState({
      open:false
    })
  };

  action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={this.handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  render() {
    if (this.state.loggedIn) {
      localStorage.setItem('name', this.state.username);
      localStorage.setItem('email', this.state.email);
      localStorage.setItem('imageUrl', this.state.imageUrl);
      return <Navigate to="/main" />;
    }
    return (
      <div>
        <br/>
        <GoogleOAuthProvider clientId="465833983261-be9064ij8s6n26eunmi1pasehicneooh.apps.googleusercontent.com">
          <GoogleLogin
            auto_select={true}
            hosted_domain="gamecircus.com"
            ux_mode={"popup"}
            useOneTap={true}
            onSuccess={credentialResponse => {
              this.responseGoogle(jwt_decode(credentialResponse.credential))
            }}
            onError={() => {
              this.setState({
                open:true
              })
            }}
          />
        </GoogleOAuthProvider>
        <Snackbar open={this.state.open}  onClose={this.handleClose} autoHideDuration={3000} action={this.action}>
                <Alert  severity="error" sx={{ width: '100%' }}>
                  <AlertTitle>Error</AlertTitle>
                    Login Failed
                </Alert>
        </Snackbar>
      </div> 
    );
  }
}

export default Oauth;
