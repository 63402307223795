import React from "react";
import Chart from "../../Component/chart/Chart";
import Dashboard from "../../Component/dashboard/Dashboard";

// import WidgetLg from "../../Component/widgetLg/WidgetLg";
// import WidgetSm from "../../Component/widgetSm/WidgetSm";
import "./home.css";
import Dropdown from "../../Component/ChartInput/Dropdown";

export default function Home() {
  return (
    <div className="home">
      <Dashboard/>
    </div>
  );
}
