import React, { Component } from "react";
import Post from "./Post";
import { userData } from "../../dummyData";
const data = [
  {
    name: "Jan",
    "Active User": 4000,
  },
  {
    name: "Feb",
    "Active User": 3000,
  },
  {
    name: "Mar",
    "Active User": 5000,
  },
  {
    name: "Apr",
    "Active User": 4000,
  },
  {
    name: "May",
    "Active User": 3000,
  },
  {
    name: "Jun",
    "Active User": 2000,
  },
  {
    name: "Jul",
    "Active User": 4000,
  },
  {
    name: "Agu",
    "Active User": 3000,
  },
  {
    name: "Sep",
    "Active User": 4000,
  },
  {
    name: "Oct",
    "Active User": 1000,
  },
  {
    name: "Nov",
    "Active User": 4000,
  },
  {
    name: "Dec",
    "Active User": 3000,
  },
];
let eve = null;

class Chart extends Component {
  constructor(props) {
    super(props)
    this.Chartid =0
    this.state = {
       postArray :[]
    }
  }

  componentWillMount() {
    eve = new EventSource("http://localhost:5000/logs")
    eve.onmessage = (event) => {
        this.addChart(event)
    }
}
  
  deleteChart = (index) =>{
    const cpypostArray = Object.assign([],this.state.postArray);
    cpypostArray.splice(index,1);
    this.setState(
      {
        postArray : cpypostArray
      }
    )
  }
  setTitle =(element) =>{
    this.setState({
      Charttitle : element.target.value
    })

  }
  addChart=(event) =>{
    let data = JSON.parse(event.data)
    let title = event.data["event_revenue"]
     this.Chartid = this.Chartid +1
     const cpypostArray = Object.assign([],this.state.postArray);
     cpypostArray.push(
       {
         id : this.Chartid,
         data : data,
         title : title,
         dataKey: "Active User",
       }
     )
     this.setState({
      postArray :[]
      })
     this.setState({
       postArray : cpypostArray.slice(Math.max(cpypostArray.length - 3, 0))
     })
  }
  render() {
    return (
      <div>
        {this.state.postArray.map((post, index) => {
          return (
            <Post
              key ={post.id}
              data={post.data}
              title={post.title}
              grid
              dataKey={post.dataKey}
              // delete ={this.deleteChart.bind(this,index)}
            />
          );
        })}
        {/* Title :<input type = 'text' onBlur={this.setTitle} />
        <button onClick ={this.addChart}>Add Chart</button> */}
      </div>
    );
  }
}

export default Chart;
