import React from 'react'
import AppBar from '@mui/material/AppBar';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Sidebar from '../sidebar/Sidebar';
import "./topbar.css";


export default function Topbar() {
    const [open, setOpen] = React.useState(false);

    const name = localStorage.getItem("name")
    const image = localStorage.getItem("imageUrl")

    function getDrawerList(){
      return (
       <>
           <Sidebar/>
           <div className='d-options'>
              <Stack direction="row" spacing={2} className='profile-stack'>
              <Avatar alt="Remy Sharp" src={image}/>
              <Typography>
                  {name}
              </Typography>
              </Stack>
                <Link to="/login" className="link"> <Button onClick={() => {
                  localStorage.removeItem("email")
                }} color="inherit"><LogoutIcon/>Logout</Button></Link>
           </div>
          </>
    
      )
    }

    return (
     <>
         <Drawer
            anchor={"left"}
            open={open}
            onClose={() => setOpen(false)}
          >
            <div className='drawer-list'>
              {getDrawerList()}
            </div>
          </Drawer>
      <AppBar position="fixed" >
      <Toolbar>   
        <MenuIcon onClick={() => setOpen(true)}/>
      <Typography variant="h6" className='org' component="div" sx={{ flexGrow: 1 }}>
          <img src={process.env.PUBLIC_URL + "GCLogo_Footer.png"} className='gc-logo'></img>
          Gamecircus Platform
      </Typography>
      <Stack direction="row" spacing={2} className='profile-stack'>
          <Avatar alt="Remy Sharp" src={image}/>
          <Typography>
              {name}
          </Typography>
      </Stack>
     
      </Toolbar>
  </AppBar>
     </>
    )
}
