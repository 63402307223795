import React, { Component } from "react";
import Chart from "react-apexcharts";

let eve = null;
let newSeries = [];

class BarSimple extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 500
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
              'United States', 'China', 'Germany'
            ],
          }
        }, 
      };
    }

    componentDidMount() {
        eve = new EventSource("http://localhost:5000/logs")
        eve.onmessage = (event) => { 
            this.updateGraph(event)
        }
    }

    componentWillUnmount() {
        if (eve != null) {
         eve.close();
        }
     }

    updateGraph(event) {
        let data = JSON.parse(event.data)
        if (data["event_revenue"] !== null) {
            let revenue = Number(data["event_revenue"])
            this.updateState(revenue)   
        }
    }

    updateState(value) {
        console.log(value)
        let index = Math.floor(Math.random() * 10);
        let newData = this.state.series[0].data.slice(0);
        newData[index] = newData[index] + value
        newSeries = [{
            data: newData
          }]
        console.log(newData)
        this.setState({
            series: newSeries
        }, function() {
            console.log(this.state.series[0].data)
        });
        this.forceUpdate();
    }

    render() {
      return (
        <div id="chart">
            <Chart options={this.state.options} series={this.state.series} type="bar" height={450} />
        </div>
      )
    }
}

export default BarSimple