import React from 'react'
import { Link } from 'react-router-dom'
import "./error.css"

export default function Error() {

    return(
         <div className='error-main'>
            <img className="error_logo" src={process.env.PUBLIC_URL + "GCLogo_Blue.png"} alt="" />
            <Link to="/login" className="link">
              <button>Please <span>login</span> with your Gamecircus Account</button>
            </Link>
        </div>
    )

}